
  import { Component, Emit, Mixins, Prop } from "vue-property-decorator";
  import Modal from "@/components/Modal.vue";
  import { Card, Transaction } from "@/interfaces";
  import { Formatters } from "@/mixins/formatters";
  import { DateTime } from "luxon";
  import CatalogItemIcon from "@/components/CatalogItemIcon.vue";
  import Button from "@/components/Button.vue";
  import Icon from "@/components/Icon.vue";
  import Spinner from "@/components/Spinner.vue";
  import client from "@/services/ct.service";

  @Component({
    components: { Spinner, Icon, Button, CatalogItemIcon, Modal },
  })
  export default class TransactionModal extends Mixins(Formatters) {
    @Prop(Object) readonly card!: Card;
    @Prop(Object) readonly transaction!: Transaction;

    isLoading = false;

    get createdDate() {
      if (!this.transaction) return null;
      return this.dateFormat(
        this.transaction.created_at,
        DateTime.DATETIME_FULL
      );
    }

    complete() {
      if (!this.card.is_manager) return;
      if (this.transaction.complete) return;
      this.isLoading = true;
      client
        .put(`/transaction/${this.transaction.id}/complete`)
        .then(() => {
          this.isLoading = false;
          this.transaction.complete = true;
          this.$notify({
            type: "success",
            text: "Transação marcada como completa.",
          });
        })
        .catch(() => {
          this.isLoading = false;
          this.$notify({
            type: "error",
            text: "Ocorreu um erro",
          });
        });
    }

    @Emit()
    close() {
      // Just emit
    }
  }
