
  import { Component, Vue, Prop } from "vue-property-decorator";
  import { Card, HistoryPage, Transaction } from "@/interfaces";
  import client from "@/services/ct.service";
  import { AxiosResponse } from "axios";
  import Spinner from "@/components/Spinner.vue";
  import Icon from "@/components/Icon.vue";
  import HistoryItem from "@/components/TransactionItem.vue";
  import TransactionModal from "@/components/TransactionModal.vue";
  import Button from "@/components/Button.vue";

  @Component({
    components: { Button, TransactionModal, HistoryItem, Icon, Spinner },
  })
  export default class History extends Vue {
    @Prop(Object) readonly card!: Card;

    history: Transaction[] = [];
    nextPageUrl: string | null = null;
    isLoading = false;

    openTransaction: Transaction | null = null;

    loadMore(initial = false): void {
      if (this.nextPageUrl === null && !initial) return;
      this.isLoading = true;
      if (initial) {
        this.nextPageUrl = null;
      }
      client
        .get(this.nextPageUrl || `/api/card/${this.card.number}/history`)
        .then((res: AxiosResponse<HistoryPage>) => {
          this.nextPageUrl = res.data.next_page_url;
          if (initial) {
            this.history = res.data.data;
          } else {
            this.history.push(...res.data.data);
          }

          this.isLoading = false;
        });
    }

    refresh(): void {
      this.loadMore(true);
    }

    onTransactionClick(item: Transaction): void {
      this.openTransaction = item;
    }

    created(): void {
      this.refresh();
    }
  }
