
  import { Component, Mixins, Prop } from "vue-property-decorator";
  import Icon from "@/components/Icon.vue";
  import { Formatters } from "@/mixins/formatters";
  import { Transaction } from "@/interfaces";
  @Component({
    components: { Icon },
  })
  export default class TransactionItem extends Mixins(Formatters) {
    @Prop(Object) readonly transaction!: Transaction;
  }
