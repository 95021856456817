
  import { Component, Prop, Vue, Watch } from "vue-property-decorator";
  import VueBarcode from "@chenfengyuan/vue-barcode";
  import { Card } from "@/interfaces";
  import Icon from "@/components/Icon.vue";
  import client from "@/services/ct.service";

  @Component({
    components: {
      Icon,
      VueBarcode,
    },
  })
  export default class CardImage extends Vue {
    @Prop(Object) readonly card!: Card;
    @Prop({ type: Boolean, default: false }) readonly mini!: boolean;

    isFlipped = true;
    imageSrc: string | null = null;

    get barcodeOptions(): Record<string, any> {
      return {
        format: "CODE128A",
        displayValue: false,
        margin: 0,
        background: "transparent",
        lineColor: "white",
        height: 30,
        width: 2,
      };
    }

    @Watch("card", { immediate: true })
    loadCardImage(): void {
      if (this.card.image) {
        client
          .get(this.card.image, {
            responseType: "arraybuffer",
          })
          .then((res) => {
            const image = btoa(
              new Uint8Array(res.data).reduce(
                (data, byte) => data + String.fromCharCode(byte),
                ""
              )
            );
            this.imageSrc = `data:${res.headers[
              "content-type"
            ].toLowerCase()};base64,${image}`;
            this.isFlipped = false;
          })
          .catch((err) => {
            this.imageSrc = null;
            this.isFlipped = false;
          });
      } else {
        this.imageSrc = null;
        this.isFlipped = false;
      }
    }
  }
