
  import { Component, Emit, Vue } from "vue-property-decorator";
  import { Action, State } from "vuex-class";
  import { UserWithCards } from "@/interfaces";

  @Component
  export default class SessionInfo extends Vue {
    @State((state) => state.auth.user) user!: UserWithCards;

    @Action("logout") doLogout!: () => Promise<void>;

    async logout(): Promise<void> {
      await this.doLogout();
      this.$notify({
        type: "success",
        text: "Sessão terminada com sucesso.",
      });
    }

    @Emit()
    changeCard(): void {
      // Just emit
    }
  }
