
  import { Component, Emit, Prop, Vue } from "vue-property-decorator";
  import { Card } from "@/interfaces";
  import PointsBar from "@/components/PointsBar.vue";
  import CardImage from "@/components/CardImage.vue";

  @Component({
    components: { CardImage, PointsBar },
  })
  export default class CardInfo extends Vue {
    @Prop(Object) readonly card!: Card;

    @Emit()
    refresh() {
      // Just emit
    }
  }
