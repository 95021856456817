import Vue from "vue";
import Vuex from "vuex";
import { Card, CardWithUsers, UserWithCards } from "@/interfaces";
import client from "@/services/ct.service";
import { AxiosResponse } from "axios";

Vue.use(Vuex);

interface State {
  auth: {
    user?: UserWithCards;
  };
  cards: {
    [number: string]: Card;
  };
}

export default new Vuex.Store<State>({
  state: {
    auth: {
      user: undefined,
    },
    cards: {},
  },
  getters: {
    getCard: (state) => (id: string) => state.cards[id],
  },
  mutations: {
    reset(state) {
      state.auth = {
        user: undefined,
      };
      state.cards = {};
    },
    setUser(state, user: UserWithCards) {
      state.auth.user = user;
      user.cards.forEach((card) => {
        state.cards = {
          ...state.cards,
          [card.number]: card,
        };
      });
    },
    setCard(state, card: CardWithUsers) {
      state.cards = {
        ...state.cards,
        [card.number]: card,
      };
    },
  },
  actions: {
    login({ commit, dispatch }, payload: { email: string; password: string }) {
      return new Promise((resolve, reject) => {
        client
          .post("/api/auth/login", payload)
          .then(() => {
            return dispatch("fetchMe");
          })
          .catch(reject);
      });
    },
    logout({ commit }) {
      commit("reset");
      return client.post("/api/auth/logout");
    },
    fetchMe({ commit }) {
      return new Promise((resolve, reject) => {
        client
          .get("/api/me")
          .then((res: AxiosResponse<UserWithCards>) => {
            commit("setUser", res.data);
            resolve(res.data);
          })
          .catch(reject);
      });
    },
    fetchCard({ commit }, id: string) {
      return new Promise((resolve, reject) => {
        client
          .get("/api/card/" + id)
          .then((res: AxiosResponse<CardWithUsers>) => {
            commit("setCard", res.data);
            resolve(res.data);
          })
          .catch(reject);
      });
    },
  },
});
