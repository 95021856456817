
  import { Component, Emit, Prop, Vue, Watch } from "vue-property-decorator";
  import { QrcodeStream } from "vue-qrcode-reader";
  import Header from "@/components/Header.vue";
  import { Card, Voucher } from "@/interfaces";
  import Spinner from "@/components/Spinner.vue";
  import client from "@/services/ct.service";
  import { AxiosResponse } from "axios";
  import QrCode from "@/components/QrCode.vue";
  import Button from "@/components/Button.vue";
  import Page from "@/components/Page.vue";
  @Component({
    components: {
      Page,
      Button,
      QrCode,
      Spinner,
      Header,
      QrcodeStream,
    },
  })
  export default class Scanner extends Vue {
    @Prop(Object) readonly card!: Card;
    @Prop(Boolean) readonly isOpen!: boolean;

    isLoading = false;
    voucher: Voucher | null = null;

    onDecode(result: string) {
      this.getVoucher(result);
    }

    getVoucher(id: string) {
      this.isLoading = true;
      client.get(`/api/voucher/${id}`).then((res: AxiosResponse<Voucher>) => {
        this.isLoading = false;
        this.voucher = res.data;
      });
    }

    redeem() {
      if (!this.voucher) return;
      this.isLoading = true;
      client
        .post(`/voucher/${this.voucher.id}/redeem`, { card: this.card.number })
        .then(() => {
          this.$notify({
            text: "Vale trocado com sucesso",
            type: "success",
          });
          this.back();
        });
    }

    reset() {
      this.isLoading = false;
      this.voucher = null;
    }

    @Watch("isOpen")
    onIsOpenChange(newVal: boolean) {
      if (newVal) this.reset();
    }

    @Emit()
    back() {
      // Just emit
    }
  }
