
  import { Component, Emit, Prop, Vue, Watch } from "vue-property-decorator";
  import Header from "@/components/Header.vue";
  import { Card } from "@/interfaces";
  import Input from "@/components/Input.vue";
  import Button from "@/components/Button.vue";
  import { ValidationObserver, ValidationProvider } from "vee-validate";
  import Spinner from "@/components/Spinner.vue";
  import Page from "@/components/Page.vue";
  import client from "@/services/ct.service";

  @Component({
    components: {
      Page,
      Spinner,
      Button,
      Input,
      Header,
      ValidationObserver,
      ValidationProvider,
    },
  })
  export default class AddPoints extends Vue {
    @Prop(Object) readonly card!: Card;
    @Prop(Boolean) readonly isOpen!: boolean;

    amount: number | null = null;
    description = "";

    isLoading = false;

    submit() {
      this.isLoading = true;
      client
        .post(`/card/${this.card.number}/add-points`, {
          amount: this.amount,
          description: this.description,
        })
        .then(() => {
          this.$notify({
            type: "success",
            text: "Pontos adicionados com sucesso.",
          });
          this.back();
        })
        .catch(() => {
          this.isLoading = false;
          this.$notify({
            type: "error",
            text: "Ocorreu um erro.",
          });
        });
    }

    reset() {
      this.amount = null;
      this.description = "";
      this.isLoading = false;
    }

    @Watch("isOpen")
    onIsOpenChange(newVal: boolean) {
      if (newVal) this.reset();
    }

    @Emit()
    back() {
      // Just emit
    }
  }
