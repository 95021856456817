
  import { Component, Prop, Vue } from "vue-property-decorator";
  import QrCreator from "qr-creator";

  @Component
  export default class QrCode extends Vue {
    @Prop(String) readonly text!: string;

    renderQr() {
      QrCreator.render(
        {
          text: this.text,
          radius: 0.5,
          ecLevel: "M",
          fill: "#FFFFFF",
          background: null,
          size: 500,
        },
        this.$refs.canvas as HTMLElement
      );
    }

    mounted() {
      this.renderQr();
    }
  }
