
  import { Component, Emit, Prop, Vue } from "vue-property-decorator";

  @Component
  export default class Button extends Vue {
    @Prop({ type: Boolean, default: false }) readonly primary!: boolean;
    @Prop({ type: Boolean, default: false }) readonly small!: boolean;
    @Prop({ type: Boolean, default: false }) readonly disabled!: boolean;
    @Prop({ type: Boolean, default: false }) readonly submit!: boolean;
    @Prop({ type: Boolean, default: false }) readonly menu!: boolean;
    @Prop({ type: Boolean, default: false }) readonly active!: boolean;

    @Emit()
    click() {
      // Just emit
    }
  }
