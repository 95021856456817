
  import { Component, Emit, Prop, Vue, Watch } from "vue-property-decorator";
  import { ValidationObserver, ValidationProvider } from "vee-validate";
  import Page from "@/components/Page.vue";
  import Header from "@/components/Header.vue";
  import { Card } from "@/interfaces";
  import Button from "@/components/Button.vue";
  import Input from "@/components/Input.vue";
  import Icon from "@/components/Icon.vue";
  import Spinner from "@/components/Spinner.vue";
  import client from "@/services/ct.service";
  @Component({
    components: {
      Spinner,
      Icon,
      Input,
      Button,
      Header,
      Page,
      ValidationObserver,
      ValidationProvider,
    },
  })
  export default class Contactless extends Vue {
    @Prop(Object) readonly card!: Card;
    @Prop(Boolean) readonly isOpen!: boolean;

    amount: number | null = null;
    description = "";
    complete = false;

    isReadScreen = false;
    isWriting = false;
    isLoading = false;
    paySuccess = false;

    abortController: AbortController = new AbortController();

    preparePayment() {
      const ndef = new window.NDEFReader();
      ndef
        .scan({ signal: this.abortController.signal })
        .then(() => {
          this.isReadScreen = true;

          ndef.onreadingerror = () => {
            this.isReadScreen = false;
            this.$notify({
              type: "error",
              text: "Ocorreu um erro ao ler da etiqueta NFC.",
            });
          };

          ndef.onreading = (event: any) => {
            const cardRecord = event.message.records.find(
              (record: any) =>
                record.recordType === "tontarias.azulluis.pt:card"
            );
            if (!cardRecord) {
              this.$notify({
                type: "error",
                text: "Cartão inválido.",
              });
            } else {
              const records = cardRecord.toRecords();
              const idRecord = records[0];
              const textDecoder = new TextDecoder(idRecord.encoding);
              const tagCardId = textDecoder.decode(records[0].data);
              if (tagCardId === this.card.number) {
                this.abortController.abort();
                this.isLoading = true;
                client
                  .post(`/card/${this.card.number}/pay`, {
                    amount: this.amount,
                    description: this.description,
                    complete: this.complete,
                  })
                  .then(() => {
                    this.isLoading = false;
                    this.paySuccess = true;
                  })
                  .catch(() => {
                    this.back();
                    this.$notify({
                      type: "error",
                      text: "Ocorreu um erro",
                    });
                  });
              } else {
                this.$notify({
                  type: "error",
                  text: "Cartão inválido.",
                });
              }
            }
          };
        })
        .catch((err: any) => {
          this.isReadScreen = false;
          this.$notify({
            type: "error",
            text: "Ocorreu um erro ao ler da etiqueta NFC.",
          });
        });
    }

    closeRead() {
      this.isReadScreen = false;
      this.abortController.abort();
    }

    writeOnCard() {
      const ndef =
        "NDEFWriter" in window
          ? new window.NDEFWriter()
          : new window.NDEFReader();
      this.isWriting = true;
      ndef
        .write(
          {
            records: [
              { recordType: "url", data: "https://tontarias.azulluis.pt/" },
              {
                recordType: "tontarias.azulluis.pt:card",
                data: {
                  records: [
                    { recordType: "text", data: this.card.number },
                    { recordType: "text", data: this.card.name },
                  ],
                },
              },
            ],
          },
          { signal: this.abortController.signal }
        )
        .then(() => {
          this.$notify({
            type: "success",
            text: `Etiqueta NFC escrita para o cartão ${this.card.number}.`,
          });
          this.isWriting = false;
        })
        .catch((err: any) => {
          this.isWriting = false;
          this.$notify({
            type: "error",
            text: "Ocorreu um erro ao escrever na etiqueta NFC.",
          });
        });
    }

    reset() {
      this.abortController.abort();
      this.isReadScreen = false;
      this.amount = null;
      this.description = "";
      this.isLoading = false;
      this.paySuccess = false;
    }

    @Emit()
    back() {
      // Just emit
    }

    @Watch("isOpen")
    onOpenChange(value: boolean) {
      if (!value) {
        this.reset();
      }
    }
  }
