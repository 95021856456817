
  import { ValidationObserver, ValidationProvider } from "vee-validate";
  import { Component, Vue } from "vue-property-decorator";
  import { Action, Mutation, State } from "vuex-class";
  import { User } from "@/interfaces";
  import Spinner from "@/components/Spinner.vue";

  @Component({
    components: {
      Spinner,
      ValidationObserver,
      ValidationProvider,
    },
  })
  export default class Login extends Vue {
    @Action fetchMe!: () => Promise<User>;
    @Action login!: (payload: {
      email: string;
      password: string;
    }) => Promise<User>;

    isLoading = false;

    email = "";
    password = "";

    submit() {
      this.isLoading = true;
      this.login({ email: this.email, password: this.password })
        .then(() => {
          // DO NOTHING FOR NOW
        })
        .catch((err) => {
          this.isLoading = false;
          this.$notify({
            type: "error",
            text: "Ocorreu um erro",
          });
        });
    }

    async created() {
      this.isLoading = true;
      try {
        await this.fetchMe();
      } catch (e) {
        this.isLoading = false;
      }
    }
  }
