
  import { Component, Emit, Prop, Vue } from "vue-property-decorator";
  import Page from "@/components/Page.vue";
  import Header from "@/components/Header.vue";
  import { State } from "vuex-class";
  import { Card } from "@/interfaces";
  import Button from "@/components/Button.vue";
  import Icon from "@/components/Icon.vue";
  import CardImage from "@/components/CardImage.vue";

  @Component({
    components: {
      CardImage,
      Icon,
      Page,
      Header,
      Button,
    },
  })
  export default class ChangeCard extends Vue {
    @State((state) => state.cards) cards!: { [id: string]: Card };
    @Prop(Boolean) readonly isOpen!: boolean;

    @Emit()
    change(card: Card): Card {
      return card;
    }

    @Emit()
    back(): void {
      // Just emit
    }

    @Emit("add-card")
    addCard(): void {
      // Just emit
    }
  }
